import {
  Batch,
  Items,
  WhereFrom,
  WhereUsed,
} from '../model/types';
import {
  mapDtoToBatch,
  mapWhereFromToBatch,
  mapWhereUsedToBatch,
} from '../model/utils';
import { baseAPI, veevaAPI, GET_BATCH } from '../../../shared/api/baseAPI';
import { VeevaResponse } from '../../cart/model/types';
import { ENDPOINTS, getUrl } from '../../../shared/config/endpoints';
import { joinQP } from '../../../shared/utils/joinQP';

export const batchesApi = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    batches: builder.query<Items<Batch>,
        { batchId: string, page: number, limit: number, useCase?: string }>({
          query: ({
            batchId, page, limit, useCase,
          }) => ({
            url: `${getUrl(ENDPOINTS.GET_BATCHES, { num: batchId, useCase })}?page=${page}&limit=${limit}`,
          }),
          transformResponse: mapDtoToBatch,
          providesTags: () => [GET_BATCH],
        }),
    batchesWhereFrom: builder
      .query<Items<WhereFrom.BatchGenealogy>,
    {batchId: string, page: number, limit: number, useCase?: string}>({
      query: ({
        batchId, page, limit, useCase,
      }) => ({
        url: `${getUrl(ENDPOINTS.GET_BATCHES_WHERE_FROM, { num: batchId, useCase })}?page=${page}&limit=${limit}`,
      }),
      transformResponse: mapWhereFromToBatch,
      providesTags: () => [GET_BATCH],
    }),
    batchesWhereFromGenealogy: builder
      .query<Items<WhereFrom.BatchGenealogy>,
      {
          batchId: string,
          material: string,
          plant: string,
          page: number,
          limit?: number,
          useCase?: string,
          params: Array<{ key: string, value: string | number }>
      }>({
        query: ({
          batchId, material, plant, page, limit, params, useCase,
        }) => ({
          url: `${getUrl(ENDPOINTS.GET_GENEALOGY_WHERE_FROM, { num: batchId, useCase })}?material=${material}&plant=${plant}&page=${page}&limit=${limit}&${joinQP(params)}`,
        }),
        transformResponse: mapWhereFromToBatch,
        providesTags: () => [GET_BATCH],
      }),
    batchesWhereUsed: builder
      .query<Items<WhereUsed.BatchGenealogy>,
      {batchId: string, page: number, limit: number, useCase?: string}>({
        query: ({
          batchId, page, limit, useCase,
        }) => ({
          url: `${getUrl(ENDPOINTS.GET_BATCHES_WHERE_USED, { num: batchId, useCase })}?page=${page}&limit=${limit}`,
        }),
        transformResponse: mapWhereUsedToBatch,
        providesTags: () => [GET_BATCH],
      }),
    batchesWhereUsedGenealogy: builder
      .query<Items<WhereUsed.BatchGenealogy>,
      {
          batchId: string,
          material: string,
          plant: string,
          page: number,
          limit?: number,
          useCase?: string,
          params: Array<{ key: string, value: string | number }>
      }>({
        query: ({
          batchId, material, plant, page, limit, params, useCase,
        }) => ({
          url: `${getUrl(ENDPOINTS.GET_GENEALOGY_WHERE_USED, { num: batchId, useCase })}?material=${material}&plant=${plant}&page=${page}&limit=${limit}&${joinQP(params)}`,
        }),
        transformResponse: mapWhereUsedToBatch,
        providesTags: () => [GET_BATCH],
      }),
    autocomplete: builder.query<Array<Batch['batchId']>, {sub: string, useCase?: string}>({
      query: ({ sub, useCase }: {sub: string, useCase?: string}) => ({
        url: getUrl(ENDPOINTS.GET_AUTOCOMPLETE_BATCH, { num: sub, useCase }),
      }),
    }),
    autocompleteWhereUsed: builder.query<Array<Batch['batchId']>, {sub: string, useCase?: string}>({
      query: ({ sub, useCase }: { sub: string, useCase?: string }) => ({
        url: getUrl(ENDPOINTS.GET_AUTOCOMPLETE_WHERE_USED, { num: sub, useCase }),
      }),
    }),
    autocompleteWhereFrom: builder.query<Array<Batch['batchId']>, {sub: string, useCase?: string}>({
      query: ({ sub, useCase }) => ({
        url: getUrl(ENDPOINTS.GET_AUTOCOMPLETE_WHERE_FROM, { num: sub, useCase }),
      }),
    }),
    whereFromFilters: builder.query<
        { body: Array<string> },
        {
            batchId: string,
            filterName: string,
            flow: string,
            useCase: string,
            params: Array<{key: string, value: string | number}>
        }>({
          query: ({
            filterName, batchId, params = [], flow, useCase,
          }) => ({
            url: `${getUrl(
              ENDPOINTS.GET_GENEALOGY_FILTERS,
              {
                filter: filterName,
                num: batchId,
                flow,
                useCase,
              },
            )}?${joinQP(params)}`,
          }),
        }),
  }),
});

type BatchDTO = {
    batchID: string
    countryName?: string
    externalBatchID?: string
    objectTypeName?: string
    materialID: string
    plant: string
    quantityV?: string
    protocolName?: string
}

type GenealogyBatchDTO = {
    id: string,
    level: string,
    plant: string,
    materialBatchType: string,
    supplyChainStage: string,
    parentBatch: string,
    dateOfExpiry: string,
    materialId: string,
    purchaseOrder: string | null,
    vendorLotNumber: string | null,
}

type VeevaObjectDTO<T extends object = GenealogyBatchDTO> = {
    recordNumber: string
    batches: Array<T>
}

export const mapBatchToVeevaObjectDTO = (
  batches: Batch[],
  state: RootState,
): VeevaObjectDTO<BatchDTO> => ({
  recordNumber: state?.config?.recordNum || '',
  batches: batches
    .map((batch) => ({
      batchID: batch.batchId,
      materialID: String(batch.materialId),
      plant: String(batch.plantCode),
    })),
});

export const mapBatchToVeevaObjectWhereFromDTO = (
  batches: WhereFrom.BatchGenealogy[],
  state: RootState,
): VeevaObjectDTO =>
  ({
    recordNumber: state?.config?.recordNum || '',
    batches: batches.map((el) => ({
      id: String(el.batchId),
      plant: String(el.plantCode),
      dateOfExpiry: String(el.expiryDate).split('T')[0],
      level: String(el.batchLevel),
      materialBatchType: el.materialType,
      parentBatch: el.parentBatch,
      materialId: String(el.materialId),
      purchaseOrder: el.purchaseOrderNumber || '',
      supplyChainStage: el.supplyChainStageCode,
      dateOfManufacture: String(el.dateOfManufacture).split('T')[0],
      vendorLotNumber: el.vendorLotNumber,
    })),
  });

export const mapBatchToVeevaObjectWhereUsedDTO = (
  batches: WhereUsed.BatchGenealogy[],
  state: RootState,
): VeevaObjectDTO => ({
  recordNumber: state?.config?.recordNum || '',
  batches: batches.map((el) => ({
    id: String(el.batchId),
    plant: String(el.plantCode),
    dateOfExpiry: String(el.expiryDate).split('T')[0],
    level: String(el.batchLevel),
    materialBatchType: el.materialType,
    parentBatch: el.parentBatch,
    materialId: String(el.materialId),
    purchaseOrder: el.purchaseOrderNumber || '',
    supplyChainStage: el.supplyChainStageCode,
    dateOfManufacture: String(el.dateOfManufacture).split('T')[0],
    vendorLotNumber: el.vendorLotNumber,
  })),
});

type SafetyBatch = Omit<GenealogyBatchDTO, 'id'>
type SafetyPayload = SafetyBatch & {batchId: string}

export const mapBatchToVeevaSafetyObjectWhereUsedDTO = (
  batches: WhereUsed.BatchGenealogy[],
  state: RootState,
): VeevaObjectDTO<SafetyPayload> => ({
  recordNumber: state?.config?.recordNum || '',
  batches: batches.map((el) => ({
    batchId: el.batchId,
    plant: el.plantCode,
    dateOfExpiry: el.expiryDate ? String(el.expiryDate).split('T')[0] : null,
    level: el.batchLevel,
    materialBatchType: el.materialType,
    parentBatch: el.parentBatch,
    materialId: el.materialId,
    materialDescription: el.materialDescription,
    purchaseOrder: el.purchaseOrderNumber,
    supplyChainStage: el.supplyChainStageCode,
    dateOfManufacture: el.dateOfManufacture ? String(el.dateOfManufacture).split('T')[0] : null,
    vendorLotNumber: el.vendorLotNumber,
  })),
} as unknown as VeevaObjectDTO<SafetyPayload>);

export const veevaObjectsApi = veevaAPI.injectEndpoints({
  endpoints: (builder) => ({
    saveVeevaObject: builder.mutation<VeevaResponse, {name?: string, batch: VeevaObjectDTO}>({
      query: ({ batch }) => ({
        url: getUrl(ENDPOINTS.SAVE_BATCH, {}),
        method: 'POST',
        body: batch,
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json',
        },
      }),
    }),
    saveVeevaObjectGenealogyWhereFrom: builder.mutation<
        VeevaResponse, {name?: string, batch: VeevaObjectDTO}>({
          query: ({ batch }) => ({
            url: getUrl(ENDPOINTS.SAVE_BATCH_WHERE_FROM, {}),
            method: 'POST',
            body: batch,
            headers: {
              'Content-type': 'application/json',
              Accept: 'application/json',
            },
          }),
        }),
    saveVeevaObjectGenealogyWhereUsed: builder.mutation<
        VeevaResponse, {name?: string, batch: VeevaObjectDTO, useCase: string}>({
          query: ({ batch, useCase }) => ({
            url: getUrl(ENDPOINTS.SAVE_BATCH_WHERE_USED, { useCase }),
            method: 'POST',
            body: batch,
            headers: {
              'Content-type': 'application/json',
              Accept: 'application/json',
            },
          }),
        }),
    saveVeevaObjectDrugsBatchValidation: builder.mutation<
        VeevaResponse, {name?: string, batch: VeevaObjectDTO}>({
          query: ({ batch }) => ({
            url: getUrl(ENDPOINTS.SAVE_SAFATY_BATCH_DRUGS, { useCase: 'batch' }),
            method: 'POST',
            body: batch,
            headers: {
              'Content-type': 'application/json',
              Accept: 'application/json',
            },
          }),
        }),
    saveVeevaObjectDrugsBatchGenealogy: builder.mutation<
        VeevaResponse, {name?: string, batch: VeevaObjectDTO}>({
          query: ({ batch }) => ({
            url: getUrl(ENDPOINTS.SAVE_SAFATY_BATCH_DRUGS, { useCase: 'genealogyWhereUsed' }),
            method: 'POST',
            body: batch,
            headers: {
              'Content-type': 'application/json',
              Accept: 'application/json',
            },
          }),
        }),
    saveVeevaObjectDrugsBatchGenealogyWhereFrom: builder.mutation<
        VeevaResponse, {name?: string, batch: VeevaObjectDTO}>({
          query: ({ batch }) => ({
            url: getUrl(ENDPOINTS.SAVE_SAFATY_BATCH_DRUGS, { useCase: 'genealogyWhereFrom' }),
            method: 'POST',
            body: batch,
            headers: {
              'Content-type': 'application/json',
              Accept: 'application/json',
            },
          }),
        }),
    saveVeevaObjectDevicesBatchValidation: builder.mutation<
        VeevaResponse, {name?: string, batch: VeevaObjectDTO}>({
          query: ({ batch }) => ({
            url: getUrl(ENDPOINTS.SAVE_SAFATY_BATCH_DEVICE, { useCase: 'batch' }),
            method: 'POST',
            body: batch,
            headers: {
              'Content-type': 'application/json',
              Accept: 'application/json',
            },
          }),
        }),
    saveVeevaObjectDevicesBatchGenealogy: builder.mutation<
        VeevaResponse, {name?: string, batch: VeevaObjectDTO}>({
          query: ({ batch }) => ({
            url: getUrl(ENDPOINTS.SAVE_SAFATY_BATCH_DEVICE, { useCase: 'genealogyWhereUsed' }),
            method: 'POST',
            body: batch,
            headers: {
              'Content-type': 'application/json',
              Accept: 'application/json',
            },
          }),
        }),

    saveVeevaObjectDevicesBatchGenealogyWhereFrom: builder.mutation<
        VeevaResponse, {name?: string, batch: VeevaObjectDTO}>({
          query: ({ batch }) => ({
            url: getUrl(ENDPOINTS.SAVE_SAFATY_BATCH_DEVICE, { useCase: 'genealogyWhereFrom' }),
            method: 'POST',
            body: batch,
            headers: {
              'Content-type': 'application/json',
              Accept: 'application/json',
            },
          }),
        }),
  }),
});

export const {
  useSaveVeevaObjectMutation,
  useSaveVeevaObjectGenealogyWhereUsedMutation,
  useSaveVeevaObjectGenealogyWhereFromMutation,
} = veevaObjectsApi;
export const { useBatchesQuery: useBatchesListQuery, useWhereFromFiltersQuery } = batchesApi;

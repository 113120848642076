import React, { MouseEventHandler } from 'react';
import { Layout, Badge } from 'antd';
import { Header } from '@mds/merck-design-system';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectProductsInCart } from '../../entyties/cart/model/Cart';
import routerConfig from '../../shared/config/routerConfig';
import { readQueryParameters } from '../../shared/utils/readQueryParameters';
import logo from '../../shared/assets/msd-w.png';
import { APP_TYPES, selectFlowType, selectConditionInboxItem } from '../../entyties/config/model/Config';

const { Sider, Content } = Layout;

export default function AppHeader() {
  const history = useHistory();
  const goToCart = () => {
    const params = new URLSearchParams({ ...readQueryParameters() });
    history.push({ pathname: routerConfig.CART, search: params.toString() });
  };

  const flow = useSelector(selectFlowType);
  const inboxItem = useSelector(selectConditionInboxItem);

  const items = useSelector(selectProductsInCart);
  const itemsCount = Object.keys(items).length;

  const onClick: MouseEventHandler<HTMLImageElement> = (e) => {
    e.stopPropagation();
    const params = new URLSearchParams({ ...readQueryParameters() });
    history.push({ pathname: routerConfig.MAIN, search: params.toString() });
  };

  return (
    <Header
      url={(
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <img
          data-testid="header-logo"
          onMouseDown={onClick}
          src={logo}
          alt="logo"
          style={{ cursor: 'pointer' }}
          className="Header__logo"
        />
      )}
      title=""
    >
      <Content />
      <Sider>
        {flow === APP_TYPES.SAFETY && inboxItem === 'inbox' ? null : (
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', marginLeft: 'auto' }}>
              <Badge count={itemsCount}>
                <ShoppingCartOutlined
                  data-testid="go-to-cart"
                  onClick={goToCart}
                  style={{
                    fontSize: '32px', color: '#fff', cursor: 'pointer',
                  }}
                />
              </Badge>
            </div>
          </div>
        )}
      </Sider>
    </Header>
  );
}

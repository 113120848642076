import { createAsyncThunk } from '@reduxjs/toolkit';
import { Batch } from '../../../entyties/batch/model/types';
import { batchesApi } from '../../../entyties/batch/api/batchesApi';

export const executeBatchSearchThunk = createAsyncThunk<
    void,
    { batchId: Batch['batchId'], page: number, limit: number },
    { state: RootState }
    >(
      'batches/executeBatchSearch',
      async (
        { batchId, page, limit },
        { dispatch, getState, rejectWithValue },
      ) => {
        try {
          const state = getState();
          const flow = state.config.key;
          const useCase = flow === 'SAFETY' ? 'safety' : '';
          const res = await dispatch(batchesApi.endpoints.batches
            .initiate({
              batchId, page, limit, useCase,
            }))
            .unwrap();
          return res as unknown as Promise<void>;
        } catch (e) {
          return rejectWithValue(e) as any;
        }
      },
    );

export const executeBatchWhereFromSearchThunk = createAsyncThunk<
    void,
    { batchId: Batch['batchId'], page: number, limit: number },
    { state: RootState }
    >(
      'batches/executeBatchWhereFromSearch',
      async ({ batchId, page, limit }, { dispatch, getState, rejectWithValue }) => {
        const state = getState();
        const flow = state.config.key;
        const useCase = flow === 'SAFETY' ? 'safety' : '';
        try {
          const res = await dispatch(batchesApi.endpoints.batchesWhereFrom
            .initiate({
              batchId, page, limit, useCase,
            }))
            .unwrap();
          return res as unknown as Promise<void>;
        } catch (e) {
          return rejectWithValue(e) as any;
        }
      },
    );

export const executeBatchWhereUsedSearchThunk = createAsyncThunk<
    any,
    { batchId: Batch['batchId'], page: number, limit: number },
    { state: RootState }
    >(
      'batches/executeBatchWhereUsedSearch',
      async ({ batchId, page, limit }, { dispatch, getState, rejectWithValue }) => {
        try {
          const state = getState();
          const flow = state.config.key;
          const useCase = flow === 'SAFETY' ? 'safety' : '';
          const res = await dispatch(batchesApi.endpoints.batchesWhereUsed
            .initiate({
              batchId, page, limit, useCase,
            }))
            .unwrap();
          return res;
        } catch (e) {
          return rejectWithValue(e) as any;
        }
      },
    );

import { createAsyncThunk } from '@reduxjs/toolkit';
import { batchesApi } from '../../../entyties/batch/api/batchesApi';

type GenealogyDataPayload = {
    batchId: string,
    material: string,
    plant: string,
    page: number,
    limit: number,
    params: Array<{key: string, value: string | number}>
}

export const getWhereFromGenealogyDataThunk = createAsyncThunk<
    void,
    GenealogyDataPayload,
    { state: RootState }
    >(
      'batches/getWhereFromGenealogyData',
      // @ts-ignore
      async (data, { dispatch, getState, rejectWithValue }) => {
        const state = getState();
        const flow = state.config.key;
        const useCase = flow === 'SAFETY' ? 'safety' : '';
        try {
          const res = await dispatch(
            batchesApi.endpoints.batchesWhereFromGenealogy.initiate({ ...data, useCase }),
          )
            .unwrap();
          return res;
        } catch (e) {
          return rejectWithValue(e) as any;
        }
      },
    );

export const getWhereUsedGenealogyDataThunk = createAsyncThunk<
    void,
    GenealogyDataPayload,
    { state: RootState }
    >(
      'batches/getWhereUsedGenealogyData',
      // @ts-ignore
      async (data, { dispatch, getState, rejectWithValue }) => {
        try {
          const state = getState();
          const flow = state.config.key;
          const useCase = flow === 'SAFETY' ? 'safety' : '';
          const res = await dispatch(batchesApi.endpoints.batchesWhereUsedGenealogy
            .initiate({ ...data, useCase }))
            .unwrap();
          return res;
        } catch (e) {
          return rejectWithValue(e);
        }
      },
    );
